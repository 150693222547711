<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <div class="prt-card-header">
                <div class="header-naming">
                  <CIcon name="cil-graph"/>
                  E-Mail Marketing Automation
                </div>
                
                <div class="header-button">
                  <CButton
                          to="funnel/new"
                          name="backagain"
                          size="sm"
                          key="key"
                          color="dark">
                    <CIcon size="sm" name="cil-plus"/>
                    <span> Neue E-Mail hinzufügen</span>
                  </CButton>
                </div>
              </div>
            </slot>
          </CCardHeader>
          <CCardBody>
            <CDataTable
                    :items="tableData"
                    :itemsPerPageSelect="{ label: 'E-Mails pro Seite' }"
                    :fields="getTableFields()"
                    hover
                    striped
                    bordered
                    fixed
                    column-filter
                    table-filter
                    :tableFilter="{ label: 'E-Mail suchen', placeholder: 'Suchbegriff eingeben' }"
                    items-per-page-select
                    sorter
                    :sorterValue="{ column: 'updated', asc: false }"
                    pagination
                    :loading="tableShowLoading"
            >
              <template #active="{item}">
                <td>
                  <CBadge :color="getTableBadge(item.active)">
                    {{item.active ? 'Aktiv' : 'Deaktiv'}}
                  </CBadge>
                </td>
              </template>
              <template #sendAfterDurationTime="{item}">
                <td v-if="item.sendAfterDurationTime > 0">
                  {{ $moment.duration(item.sendAfterDurationTime, item.sendAfterDurationType).humanize() }}
                </td>
                <td v-else>
                  Sofort
                </td>
              </template>
              <template #show_details="{item, index}">
                <td class="py-2">
                  <CButton
                          :to="'funnel/edit/' + item.id"
                          color="primary"
                          variant="outline"
                          square
                          size="sm"
                  >
                    Bearbeiten
                  </CButton>
                </td>
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>
        <CCard>
          <CCardHeader>
            <slot name="header">
              <strong>Platzhalter für E-Mails</strong>
            </slot>
          </CCardHeader>
          <CCardBody>
            <table class="vars-to-use" border="0" v-pre>
              <tbody>
                <tr>
                  <td>{{MAKLER}}</td>
                  <td>Ihr Maklername</td>
                </tr>
                <tr>
                  <td>{{IMMOBILIEN_TYP}}</td>
                  <td>Z.b. Haus, Eigentumswohnung etc.</td>
                </tr>
                <tr>
                  <td>{{ANREDE}}</td>
                  <td>Anrede </td>
                </tr>
                <tr>
                  <td>{{NACHNAME}}</td>
                  <td>Nachname des Eigentümers</td>
                </tr>
                <tr>
                  <td>{{VORNAME}}</td>
                  <td>Vorname des Eigentümers</td>
                </tr>
                <tr>
                  <td>{{TELEFON}}</td>
                  <td>Telefonnummer des Eigentümers</td>
                </tr>
                <tr>
                  <td>{{EMAIL}}</td>
                  <td>E-Mail Adresse des Eigentümers</td>
                </tr>
                <tr>
                  <td>{{ADRESSE}}</td>
                  <td>Vollständige Anschrift der Immobilie</td>
                </tr>
                <tr>
                  <td>{{WOHNFLACHE}}</td>
                  <td>Wohnfläche der Immobilie</td>
                </tr>
                <tr>
                  <td>{{ZIMMER}}</td>
                  <td>Anzahl der Zimmer der Immobilie</td>
                </tr>
                <tr>
                  <td>{{BAUJAHR}}</td>
                  <td>Baujahr der Immobilie</td>
                </tr>
                <tr>
                  <td>{{GRUNDFLACHE}}</td>
                  <td>Grundstücksfläche der Immobilie</td>
                </tr>
                <tr>
                  <td>{{ETAGE}}</td>
                  <td>Anzahl der Etagen der Immobilie</td>
                </tr>
                <tr>
                  <td>{{ERSCHLOSSEN}}</td>
                  <td>Bei Grundstücken, angabe ob Erschlossen, Teilerschlossen oder nicht erschlossen</td>
                </tr>
                <tr>
                  <td>{{BEBAUUNG}}</td>
                  <td>Bebaungsmöglichkeiten von Grundstücken</td>
                </tr>
                <tr>
                  <td>{{ZUSCHNITT}}</td>
                  <td>Angaben zum Grundstückszuschnitt</td>
                </tr>
                <tr>
                  <td>{{GRUND}}</td>
                  <td>Grund der Immobilienbewertung Kauf oder Verkauf</td>
                </tr>
                <tr>
                  <td>{{GRUND_WANN}}</td>
                  <td>Verkaufszeitwunsch, wenn "Verkauf" als Grund ausgewählt</td>
                </tr>
                <tr>
                  <td>{{GESAMT_ERGEBNIS}}</td>
                  <td>Ermittelter durchschnittlicher Marktwert</td>
                </tr>
                <tr>
                  <td>{{MIN_GESAMT_ERGEBNIS}}</td>
                  <td>Resultierende Wertspanne minimum</td>
                </tr>
                <tr>
                  <td>{{MAX_GESAMT_ERGEBNIS}}</td>
                  <td>Resultierende Wertspanne maximum</td>
                </tr>
                <tr>
                  <td>{{ERGEBNIS_PRO_QM}}</td>
                  <td>Durchschnittlicher Wert pro m² Wohnfläche</td>
                </tr>
                <tr>
                  <td>{{MIN_ERGEBNIS_PRO_QM}}</td>
                  <td>Ermittelte Wertspanne pro m² minimum</td>
                </tr>
                <tr>
                  <td>{{MAX_ERGEBNIS_PRO_QM}} </td>
                  <td>Ermittelte Wertspanne pro m² maximum</td>
                </tr>
                <tr>
                  <td>{{PDF_URL}} </td>
                  <td>Link zur ursprünglichen Wohnmarktanalyse, falls vorhanden.</td>
                </tr>
              </tbody>
            </table>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<style scoped>
  .prt-card-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .prt-card-header > div {
    text-align: right;
  }
</style>
<script>
  import axios from 'axios'
  import commons from '@/commons'

  export default {
    name: 'Funnel',
    components: {},
    data() {
      return {
        tableData: [],
        tableShowLoading: true,
      }
    },
    mounted() {
      axios.get('/funnel/rules')
          .then(response => {
            let content = []
            response.data.forEach(value => {
              content.push(commons.flatObject(value))
            })
            this.tableData = content
          })
          .catch(err => console.error('Problem while fetching statistics', err))
          .finally(() => this.tableShowLoading = false)
    },
    methods: {
      getTableFields() {
        return [
          {key: 'name', label: 'Name'},
          {key: 'subject', label: 'Betreff'},
          {key: 'active', label: 'Aktiv?'},
          {key: 'sendAfterDurationTime', label: 'Senden nach'},
          {
            key: 'show_details',
            label: '',
            _style: 'width:1%',
            sorter: false,
            filter: false
          }
        ]
      },
      getTableBadge(type) {
        return type ? 'success' : 'danger'
      },
    }
  }
</script>
